import { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { useDebounce } from '@uidotdev/usehooks';
import { useFormik } from 'formik';
import { validate } from '~/utils/validateUser';
import { useLocalStorage } from '~/utils/useLocalStorage';
import * as Tabs from '@radix-ui/react-tabs';
import { Button } from '~/ui/index';
import '~/assets/css/datepicker.css';
import cn from 'classnames';
import styles from './UserForm.module.scss';
import { GeneralFormInfo } from './GeneralFormInfo';
import { BusinessTripForm } from './BusinessTripForm';
import { USER_ROLES } from '~/utils/getUserRole';
import { IAsset } from '~/pages/Assets/Assets';
import { AuthenticationContext } from '../Authentication/AuthenticationProvider';
import { useTranslation } from 'react-i18next';
import { Accommodation } from '~/pages/Accommodations/AccommodationsBoard/AccommodationsTable/AccommodationsTable';
import { IBlockCategory } from '~/pages/Users/UsersBoard/UsersBoard';
import { getTimezones } from './timezones';
import { BUTTON_STYLES } from '~/ui/ButtonWithChildren/ButtonWithChildren';
import { Modal } from '../Shared/Modal/Modal';
import ChangeUsersStatusModalContent from '~/pages/Users/UsersBoard/ChangeUsersStatusModalContent/ChangeUsersStatusModalContent';
import { useToastSuccess } from '~/utils/useToastSuccess';

export interface UserItem {
  id: number;
  full_name?: string;
  name: string;
  uniqueId: string;
  patronymic: string;
  phone: string;
  email: string;
  password: string;
  business_trips: IUserBusinessTrip[];
  company_name: null | string | undefined;
  grade: {
    id: number;
    name: string;
  } | null;
  role_id: number | null;
  role: {
    id: number;
    name: string;
  };
  accommodations_id: number | null;
  module: string;
  organization: string;
  accommodation_section: string;
  asset_id: number;
  assets: {
    id: number;
    name: string;
  };
  have_edit_permission: { role: boolean };
  role_name: string;
  utc: string;
  timezone: {
    id: string;
    name: string;
  };
  display_role_name?: string;
  food_id?: number;
  block_list: {
    id: number;
    user_id: number;
    categories: IBlockCategory;
  }[];
}

export interface UserBusinessTrips {
  id: number;
  user_id: number;
  start_date: string;
  end_date: string;
  accommodation_id: string | number;
  created_at: string;
  updated_at: string;
}

export interface IUserBusinessTrip {
  id: number;
  user_id: number;
  start_date: string;
  end_date: string;
  accommodation_id: number;
  created_at: string;
  updated_at: string;
  accommodation: Accommodation;
  roomId: number;
}

export interface UserValues {
  name: string;
  uniqueId: string;
  patronymic: string;
  phone: string;
  email: string;
  password: string;
  organization: string;
  company_name: null | string | undefined;
  grade_id?: number | null;
  grade: {
    id: number;
    name: string;
  } | null;
  role: {
    id: number;
    name: string;
  };
  role_id: number | null;
  accommodations_id: number | null;
  accommodation_section: string;
  business_trips: IUserBusinessTrip[];
  assets: {
    id: number;
    name: string;
  };
  asset_id: number | null;
  utc: string;
  timezone: {
    id: string;
    name: string;
  };
  display_role_name?: string;
  food_id?: number;
}

interface UserFormProps {
  isLoading?: boolean;
  user?: UserItem;
  onSubmit: (values: UserValues) => void;
  storageKey?: string;
  isEditing?: boolean | undefined;
}

const ACTIVE_TABS = {
  INFO: 'info',
  CALENDAR: 'calendar',
};

export const UserForm = ({
  isLoading,
  user,
  onSubmit,
  isEditing,
  storageKey,
}: UserFormProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState(ACTIVE_TABS.INFO);
  const [isStatusUsersModalOpen, setIsStatusUsersModalOpen] = useState(false);
  const [disabledPassword, setDisabledPassword] = useState<boolean>(false);
  const [passwordValid, setPasswordValid] = useState<boolean>(false);
  const [isBlockLoading, setIsBlockLoading] = useState<{
    block: boolean;
    unblock: boolean;
  }>({
    block: false,
    unblock: false,
  });
  const [assets, setAssets] = useState<IAsset[]>([]);
  const [selectedSections, setSelectedSections] = useState<IBlockCategory[]>(
    []
  );
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const debouncedSearchTerm = useDebounce('', 500);
  const currentUser = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const client = useQueryClient();
  const params = {
    sort: '',
    perPage: 100,
    field: '',
    query: debouncedSearchTerm,
    page: 1,
  };

  const handleToggleSelectedSections = (section: IBlockCategory) => {
    if (
      selectedSections.find(
        selectedSection => selectedSection.id === section.id
      )
    ) {
      const filteredSections = selectedSections.filter(
        selectedSection => selectedSection.id !== section.id
      );
      setSelectedSections(filteredSections);
    } else {
      setSelectedSections([...selectedSections, section]);
    }
  };

  const handleBlockUsers = async () => {
    setIsBlockLoading({ ...isBlockLoading, block: true });
    try {
      await instance.post('admin/block-list/block', {
        user_ids: [user?.id],
        category_ids: selectedSections.map(section => section.id),
      });
      setIsStatusUsersModalOpen(false);
      client.invalidateQueries(['users']);
      client.invalidateQueries(['user', String(user?.id)]);
      toastSuccess(t('employee_blocked'));
      setSelectedSections([]);
    } catch (error) {
      toastError(error);
    } finally {
      setIsBlockLoading({ ...isBlockLoading, block: false });
    }
  };

  const handleUnblockUsers = async () => {
    setIsBlockLoading({ ...isBlockLoading, unblock: true });
    try {
      await instance.post('admin/block-list/unblock', {
        user_ids: [user?.id],
        category_ids: selectedSections.map(section => section.id),
      });
      setIsStatusUsersModalOpen(false);
      client.invalidateQueries(['users']);
      client.invalidateQueries(['user', String(user?.id)]);
      setSelectedSections([]);
      toastSuccess(t('employee_unblocked'));
    } catch (error) {
      toastError(error);
    } finally {
      setIsBlockLoading({ ...isBlockLoading, unblock: false });
    }
  };

  const timezones = getTimezones();

  const { data: roles } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/get-roles');
      return data;
    },
    queryKey: ['roles'],
    onError: error => {
      toastError(error);
    },
  });

  const { data } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/users-grade');
      return response.data;
    },
    queryKey: ['grades'],
    onError: error => {
      toastError(error);
    },
  });

  const grades = data?.grade;

  const { initialValues, storedInitialValues, clear, create } =
    useLocalStorage<UserValues>({
      initialValues: {
        name: '',
        uniqueId: '',
        patronymic: '',
        phone: '',
        email: '',
        password: '',
        organization: t('organisation'),
        company_name: null,
        grade: {
          id: 0,
          name: '',
        },
        role: {
          id: 0,
          name: '',
        },
        role_id: 0,
        accommodations_id: null,
        accommodation_section: '',
        business_trips: [],
        assets: {
          id: 0,
          name: '',
        },
        asset_id: null,
        timezone: timezones[0],
        utc: '',
        food_id: 0,
      },
      key: storageKey && `${storageKey}-v1`,
      exclude: ['business_trips', 'role'],
    });

  const formik = useFormik({
    initialValues: storedInitialValues,

    validate,

    onSubmit: async (values: UserValues) => {
      try {
        await onSubmit(values);
        clear();
        formik.resetForm({ values: initialValues });
      } catch (error) {
        toastError(error);
      }
    },
  });

  const { data: accommodation } = useQuery(
    ['accommodation', formik.values.accommodations_id],
    async () => {
      const response = await instance.get(
        `admin/accommodations/${formik.values.accommodations_id}`
      );
      return response.data;
    },
    {
      enabled:
        formik.values.accommodations_id !== null &&
        formik.values.accommodations_id !== undefined,

      onError: error => {
        toastError(error);
      },
    }
  );
  create(formik.values);

  const setValues = formik.setValues;

  useEffect(() => {
    if (!user) return;

    setDisabledPassword(!!user?.password);

    setValues({
      name: user.name,
      uniqueId: user.uniqueId,
      patronymic: user.patronymic,
      phone: user.phone,
      email: user.email,
      password: user.password,
      business_trips: user.business_trips,
      company_name: user.company_name,
      role: roles?.find(
        (role: { id: number; name: string }) => role.id === user.role_id
      ),
      role_id: 0,
      grade: user.grade,
      accommodations_id: user.accommodations_id,
      organization: user.organization,
      accommodation_section: user.accommodation_section,
      assets: user.assets,
      asset_id: user.asset_id,
      display_role_name: user.display_role_name,
      timezone: timezones.find(timezone => user.utc === timezone.id) || {
        id: '',
        name: '',
      },
      utc: user.utc,
      food_id: user.food_id,
    });
  }, [user, setValues, storedInitialValues]);

  const loadAssets = async () => {
    try {
      const { data } = await instance.get('admin/assets');
      setAssets(data.data);
    } catch {
      toastError(t('failed_to_load_assets'));
    }
  };

  useEffect(() => {
    if (formik.values.role.name) {
      if (!assets.length) {
        loadAssets();
      }
      formik.setFieldValue('company_name', '');
    } else {
      formik.setFieldValue('asset_id', null);
    }
  }, [formik.values.role]);

  useEffect(() => {
    if (roles?.length === 1) {
      formik.setFieldValue('role_id', roles[0].id);
    }
  }, [roles]);

  return (
    <Tabs.Root
      className={styles.tabsRoot}
      value={activeTab}
      onValueChange={activeTab => setActiveTab(activeTab)}
    >
      <Tabs.List className={styles.tabsList} aria-label="Manage your account">
        <Tabs.Trigger
          value={ACTIVE_TABS.INFO}
          className={cn(styles.tabsTrigger, {
            [styles.tabsTriggerActive]: activeTab === ACTIVE_TABS.INFO,
          })}
        >
          {t('general_information')}
        </Tabs.Trigger>
        <Tabs.Trigger
          value={ACTIVE_TABS.CALENDAR}
          className={cn(styles.tabsTrigger, {
            [styles.tabsTriggerActive]: activeTab === ACTIVE_TABS.CALENDAR,
          })}
        >
          {t('trips')}
        </Tabs.Trigger>
      </Tabs.List>
      <div className={styles.formWrapper}>
        <form
          className={styles.form}
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Tabs.Content
            tabIndex={undefined}
            className={styles.tabsContent}
            value={ACTIVE_TABS.INFO}
          >
            <GeneralFormInfo
              isLoading={isLoading}
              roleName={user?.display_role_name || ''}
              timezonesList={timezones}
              assets={assets}
              roles={roles}
              isUserCurrent={
                Number(user?.id) === Number(currentUser?.user?.data.id)
              }
              grades={grades}
              companyName={user?.company_name}
              disabledPassword={disabledPassword}
              setPasswordValid={setPasswordValid}
              setDisabledPassword={setDisabledPassword}
              formik={formik}
              isAssetSelectShown={
                formik.values.role.name === USER_ROLES.ASSET_ADMIN
              }
              isFoodSelectShown={
                formik.values.role.name === USER_ROLES.FOOD_ADMIN
              }
              isRoleEditable={user?.have_edit_permission?.role}
            />
          </Tabs.Content>
          <Tabs.Content
            tabIndex={undefined}
            className={styles.tabsContent}
            value={ACTIVE_TABS.CALENDAR}
          >
            <BusinessTripForm
              isEditing={isEditing}
              formik={formik}
              accommodation={accommodation}
              params={params}
            />
          </Tabs.Content>
          {formik.errors.business_trips && (
            <span className={styles.tripsErrors}>
              {String(formik.errors.business_trips)}
            </span>
          )}
          <div className={styles.controls}>
            <Button
              type="submit"
              text={t('save_n_exit')}
              className={styles.submitButton}
              disabled={
                !(formik.isValid || formik.isSubmitting) || !passwordValid
              }
            />
            <Button
              onClick={() => setIsStatusUsersModalOpen(true)}
              type="button"
              text={t('change_status')}
              className={styles.changeStatusButton}
              buttonStyle={BUTTON_STYLES.EMPTY}
            />
          </div>
        </form>
        <Modal
          isOpen={isStatusUsersModalOpen}
          onClose={() => setIsStatusUsersModalOpen(false)}
        >
          <ChangeUsersStatusModalContent
            isLoading={isBlockLoading}
            selectedSections={selectedSections}
            onCancelPress={() => setIsStatusUsersModalOpen(false)}
            handleToggleCheckbox={handleToggleSelectedSections}
            handleSubmitUnblock={handleUnblockUsers}
            handleSubmit={handleBlockUsers}
          />
        </Modal>
      </div>
    </Tabs.Root>
  );
};
