import type { Dayjs } from 'dayjs';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useDebounce } from '@uidotdev/usehooks';
import { Board } from '~/components/Shared/Layout/Board/Board';
import { ErrorMessage } from '~/components/Shared/ErrorMessage/ErrorMessage';
import { InputBar, Pagination, Spinner } from '~/ui';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import { instance } from '~/utils/api/api';
import { OrdersTable } from './OrdersTable';
import styles from './OrdersBoard.module.scss';
import FoodExportActions from '~/components/FoodExportActions/FoodExportActions';
import { useTranslation } from 'react-i18next';

interface SelectedPage {
  selected: number;
}

interface OrdersBoardProps {
  date: Dayjs | null;
}

export const OrdersBoard = (props: OrdersBoardProps): JSX.Element => {
  const { date } = props;
  const { foodID } = useParams();

  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [page, setPage] = useState<number>();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { t } = useTranslation();

  const params = {
    sort: sort.direction,
    perPage: 15,
    field: sort.field,
    query: debouncedSearchTerm,
    page,
    created_at: date?.format('YYYY-MM-DD'),
  };

  const {
    data: orders,
    isError,
    error,
  } = useQuery({
    queryFn: async () => {
      const response = await instance.get(`admin/foods/${foodID}/orders`, {
        params,
      });
      return response.data;
    },
    queryKey: ['transactions', foodID, params],
    keepPreviousData: true,
  });

  let content: React.ReactNode;

  if (orders?.data) {
    content = (
      <OrdersTable orders={orders.data} sort={sort} setSort={setSort} />
    );
  } else if (isError) {
    content = <ErrorMessage error={error} />;
  } else {
    content = <Spinner />;
  }

  return (
    <Board>
      <div className={styles.options}>
        <InputBar
          onChange={e => {
            setSearchTerm(e.target.value);
            setPage(1);
          }}
          value={searchTerm}
          placeholder={`${t('search_by_id_nn')}..`}
          classNameInput={styles.input}
          label={`${t('search')}:`}
          star={false}
          classNameInputWrapper={styles.inputWrapper}
        />
        <FoodExportActions foodID={+foodID!} />
      </div>
      {content}
      <div className={styles.pagination}>
        <TableAmountPage
          firstRow={orders?.meta?.from}
          lastRow={orders?.meta?.to}
          total={orders?.meta?.total}
        />
        <Pagination
          pageCount={1}
          onPageChange={(selectedPage: SelectedPage) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
    </Board>
  );
};
