import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { useTranslation } from 'react-i18next';
import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';
import Tooltip from '~/components/Shared/Tooltip/Tooltip';
import InfoIcon from '~/assets/svg/newSvg/info-icon.svg?react';
import InfoIconGreen from '~/assets/svg/newSvg/info-icon-green.svg?react';
import styles from './TransactionTable.module.scss';
import { FoodWares } from '../TransactionForm';

interface Props {
  order: FoodWares[];
  selectedItems: number[];
  isAllCancelled: boolean;
  onCheckboxHeadPress: () => void;
  onCheckboxPress: (id: number) => void;
}

export const TransactionTable = ({
  order,
  isAllCancelled,
  onCheckboxPress,
  selectedItems,
  onCheckboxHeadPress,
}: Props): JSX.Element => {
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const { t } = useTranslation();

  const getCancelledByTranslated = (user: string) => {
    if (user === 'user') {
      return t('by_user');
    }
    return t('by_admin');
  };

  return (
    <div
      style={
        isAllCancelled ? { backgroundColor: 'rgba(75, 70, 92, 0.08)' } : {}
      }
      className={styles.tableWrapper}
    >
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead
            className={styles.tableCellHead}
            sortable={false}
            field="checkbox"
          >
            {order?.filter(({ is_cancel }) => !is_cancel).length ? (
              <CustomCheckbox
                isChecked={
                  selectedItems.length === order.length && !!order.length
                }
                onChange={onCheckboxHeadPress}
              />
            ) : (
              ''
            )}
          </TableCellHead>
          <TableCellHead sortable={false} field="name">
            {t('name')}
          </TableCellHead>
          <TableCellHead sortable={false} field="category">
            {t('category')}
          </TableCellHead>
          <TableCellHead sortable={false} field="weight">
            {t('measure')}
          </TableCellHead>
          <TableCellHead sortable={false} field="price">
            {t('price')}
          </TableCellHead>
          <TableCellHead sortable={false} field="amount">
            {t('quantity_full')}
          </TableCellHead>
          <TableCellHead sortable={false} field="sum">
            {t('total_amount')}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {order?.map(
            ({
              id,
              wares,
              qty,
              total,
              is_cancel,
              cancel_by,
              is_submit,
            }: FoodWares) => (
              <TableRow style={is_cancel ? styles.transactionRow : ''} key={id}>
                <TableCell className={styles.checkboxCell}>
                  {is_cancel ? (
                    <Tooltip
                      tooltipStyle={styles.tooltipStyle}
                      className={styles.transactionsTooltip}
                      position="right"
                      title={`${t(
                        'dish_cancelled_by'
                      )} ${getCancelledByTranslated(cancel_by)}`}
                    >
                      <InfoIcon className={styles.tooltipIcon} />
                    </Tooltip>
                  ) : is_submit ? (
                    <Tooltip
                      tooltipStyle={styles.tooltipStyle}
                      className={styles.transactionsTooltip}
                      position="right"
                      title={t('confirmed_dish')}
                    >
                      <InfoIconGreen
                        color="green"
                        className={styles.tooltipIconGreen}
                      />
                    </Tooltip>
                  ) : (
                    <CustomCheckbox
                      isChecked={selectedItems.includes(id)}
                      onChange={() => onCheckboxPress(id)}
                    />
                  )}
                </TableCell>
                <TableCell>{wares.wares_name}</TableCell>
                <TableCell>{wares.parent}</TableCell>
                <TableCell>{wares.weight}</TableCell>
                <TableCell>{wares.price_list.price}&#8381;</TableCell>
                <TableCell>{qty}</TableCell>
                <TableCell>{total}&#8381;</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
        <TableRow>
          <TableCell>
            <span className={styles.boldText}>{t('in_total')}:</span>
          </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>
            <span className={styles.boldText}>
              {order?.map(el => el.qty).reduce((acc, curr) => acc + curr, 0)}
            </span>
          </TableCell>
          <TableCell>
            <span className={styles.boldText}>
              {order?.map(el => el.total).reduce((acc, curr) => acc + curr, 0)}
              &#8381;
            </span>
          </TableCell>
        </TableRow>
      </Table>
    </div>
  );
};
