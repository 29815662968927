import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button, InputBar, Spinner, Textarea } from '~/ui/index';
import styles from './TransactionForm.module.scss';
import { useTranslation } from 'react-i18next';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Tooltip from '~/components/Shared/Tooltip/Tooltip';
import ModalConfirm from '~/components/Shared/ModalConfirm';
import { TransactionTable } from './TransactionTable/TransactionTable';
import PaymentBlock from '../PaymentBlock/PaymentBlock';

export interface TransactionItem {
  user_name: string;
  id: number;
  created_at: string;
  status_title: string;
  food_wares: FoodWares[];
  total_sum: number;
  food_name: string;
  comment: string;
  can_cancel: boolean;
  status?: number;
  payment_type: {
    id: number;
    name: string;
    type: string;
  };
  payment_status: string;
}

export interface FoodWares {
  id: number;
  qty: number;
  total: number;
  wares: Wares;
  is_cancel: boolean;
  cancel_by: string;
  is_submit: boolean;
  parent_code: string;
  pivot: {
    is_cancel: boolean;
    is_submit: boolean;
    qty: number;
  };
}

export interface Wares {
  wares_name: string;
  parent: string;
  weight: string;
  price_list: { price: string };
  photos: {
    id: number;
    full_photo_url: string;
  }[];
}

interface Props {
  transaction: TransactionItem;
}

interface TransactionParams {
  transactionID: string;
}

export const TransactionForm = ({ transaction }: Props) => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const { transactionID } = useParams<
    keyof TransactionParams
  >() as TransactionParams;
  const client = useQueryClient();
  const { t } = useTranslation();

  const initialValueFoodWares = {
    id: '',
    qty: '',
    total: '',
    wares: {
      wares_name: '',
      parent: '',
      weight: '',
      price_list: { price: '' },
    },
  };

  const formik = useFormik({
    initialValues: {
      user_name: '',
      id: '',
      created_at: '',
      status_title: '',
      food_wares: [initialValueFoodWares],
      total_sum: '',
      food_name: '',
      comment: '',
    },

    onSubmit: () => {
      formik.resetForm();
    },
  });

  const handleCancelDishes = async () => {
    setIsLoading(true);
    try {
      const { data } = await instance.delete(`admin/food-order-wares`, {
        data: {
          orders_data: [
            {
              order_id: transaction?.id,
              order_ware_ids: selectedItems,
            },
          ],
        },
      });
      toastSuccess(data?.message || t('cancel_dish'));
      client.invalidateQueries(['transaction', transactionID]);
      setSelectedItems([]);
    } catch (error: any) {
      toastError(error?.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelect = (id: number) => {
    if (selectedItems.includes(id)) {
      const filteredFoods = selectedItems.filter(
        selectedId => selectedId !== id
      );
      setSelectedItems(filteredFoods);
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectedItems.length === transaction?.food_wares?.length) {
      setSelectedItems([]);
    } else {
      const foodIdArray = transaction?.food_wares?.map((transaction: any) => {
        if (!transaction?.is_cancel) {
          return transaction?.id;
        }
      });
      setSelectedItems(foodIdArray);
    }
  };

  const { mutate: deleteOrder } = useMutation(
    async (id: string) => {
      setIsLoading(true);
      if (id) {
        await instance.delete(`admin/food-orders/${id}`);
      }
    },
    {
      onSuccess: () => {
        setIsLoading(false);
        client.invalidateQueries(['transactions']);
        toastSuccess(t('order_deleted'));
        navigate('/transactions');
      },
      onError: (error: any) => {
        setIsLoading(false);
        toastError(error?.response.data.error);
      },
    }
  );

  const isDisabled = !transaction?.can_cancel;

  useEffect(() => {
    if (!transaction) return;

    formik.setValues({
      user_name: transaction?.user_name,
      id: transaction?.id.toString(),
      created_at: transaction?.created_at,
      status_title: transaction?.status_title,
      food_wares: transaction?.food_wares.map(foodWare => ({
        id: foodWare.id.toString(),
        qty: foodWare.qty.toString(),
        total: foodWare.total.toString(),
        wares: {
          wares_name: foodWare.wares.wares_name,
          parent: foodWare.wares.parent,
          weight: foodWare.wares.weight,
          price_list: { price: foodWare.wares.price_list.price },
        },
      })),
      total_sum: transaction?.total_sum.toString(),
      food_name: transaction?.food_name,
      comment: transaction?.comment,
    });
  }, [transaction]);

  return (
    <div className={styles.formWrapper}>
      {transaction?.payment_type ? (
        <PaymentBlock
          name={transaction?.payment_type.name}
          type={transaction?.payment_type.type}
          status_type={transaction?.payment_status}
        />
      ) : (
        ''
      )}
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.formTop}>
          <div className={styles.infoWrapper}>
            <InputBar
              name="user_name"
              value={formik.values.user_name}
              disabled={true}
              label={t('employee')}
            />

            <InputBar
              name="id"
              value={formik.values.id}
              disabled={true}
              label={t('order_number')}
            />

            <InputBar
              name="created_at"
              value={formik.values.created_at}
              disabled={true}
              label={t('order_date')}
            />
          </div>
          <InputBar
            name="food_name"
            value={formik.values.food_name}
            disabled={true}
            label={t('food_object')}
          />

          <div className={styles.input__label}>
            <div className={styles.input__head}>
              <span className={styles.input__text}>{t('compound')}</span>
              <div className={styles.button__wrapper}>
                {!!selectedItems.length && (
                  <div className={styles.cancelButtonWrapper}>
                    <ModalConfirm
                      title={`${t('cancel_dishes_confirmation')}`}
                      onConfirm={handleCancelDishes}
                    >
                      {open => (
                        <Button
                          type="button"
                          text={t('cancel')}
                          color="red"
                          onClick={open}
                          className={styles.deleteButton}
                          disabled={isDisabled}
                        />
                      )}
                    </ModalConfirm>
                  </div>
                )}
                {transaction?.status !== 3 ? (
                  <>
                    {isDisabled ? (
                      <div className={styles.cancelButtonWrapper}>
                        <Tooltip
                          tooltipStyle={styles.transactionTooltip}
                          title={t('canceled_tooltip')}
                        >
                          <Button
                            color="red"
                            text={t('cancel_order')}
                            onClick={() => deleteOrder(transactionID)}
                            disabled={isDisabled}
                            className={styles.deleteButton}
                          />
                        </Tooltip>
                      </div>
                    ) : (
                      <div className={styles.cancelButtonWrapper}>
                        <ModalConfirm
                          title={`${t(
                            'cancel_order_confirmation'
                          )} №${transactionID}?`}
                          onConfirm={() => deleteOrder(transactionID)}
                        >
                          {open => (
                            <Button
                              color="red"
                              text={t('cancel_order')}
                              onClick={open}
                              disabled={isLoading}
                              className={styles.deleteButton}
                            />
                          )}
                        </ModalConfirm>
                      </div>
                    )}
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className={styles.transactionWrapper}>
              {isLoading ? (
                <div className={styles.loader}>
                  <Spinner />
                </div>
              ) : (
                ''
              )}
              <TransactionTable
                isAllCancelled={transaction?.status === 3}
                selectedItems={selectedItems}
                onCheckboxHeadPress={handleSelectAll}
                onCheckboxPress={handleSelect}
                order={transaction?.food_wares}
              />
            </div>
          </div>

          <Textarea
            name="comment"
            rows={4}
            star={false}
            maxLength={1000}
            label={t('comment')}
            disabled
            value={transaction?.comment || t('no_comment')}
          />
        </div>
        <Button
          type="submit"
          text={t('back_to_list')}
          className={styles.btn}
          onClick={goBack}
          disabled={false}
        />
      </form>
    </div>
  );
};
